import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMaintenanceList() {
      return new Promise((resolve, reject) => {
        axios
          .get('/asset/maintenance')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMaintenance(ctx, maintenanceData) {
      return new Promise((resolve, reject) => {
        axios
          .put('/asset/maintenance', maintenanceData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMaintenance(ctx, maintenanceData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/asset/maintenance', maintenanceData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMaintenance(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/asset/maintenance/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
